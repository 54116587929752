<template>
  <div>
    <article class="account" v-if="confirmed === true">
      <h2 class="account__heading">Success</h2>
      <section class="account__content post-content confirmMessage">
        <p>You have successfully confirmed adding supplier</p>
      </section>
    </article>
    <article class="account" v-if="confirmed === false">
      <h2 class="account__heading">Error</h2>
      <section class="account__content post-content confirmMessage">
        <p>Something went wrong</p>
      </section>
    </article>
    <div class="loader" :class="{ 'is-visible': loading }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      confirmed: null,
      userEmail: null,
    };
  },
  async mounted() {
    let { guid } = this.$route.query;

    try {
      const { confirmed } = await this.$store.user.confirmSupplierEmail(guid);

      this.loading = false;
      this.confirmed = confirmed;
    } catch (error) {
      this.loading = false;
      this.confirmed = false;
      console.log(error);
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';

.confirmMessage {
  text-align: center;
}
</style>
