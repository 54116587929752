var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.confirmed === true
      ? _c("article", { staticClass: "account" }, [
          _c("h2", { staticClass: "account__heading" }, [_vm._v("Success")]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.confirmed === false
      ? _c("article", { staticClass: "account" }, [
          _c("h2", { staticClass: "account__heading" }, [_vm._v("Error")]),
          _vm._v(" "),
          _vm._m(1),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "loader", class: { "is-visible": _vm.loading } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "account__content post-content confirmMessage" },
      [_c("p", [_vm._v("You have successfully confirmed adding supplier")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "account__content post-content confirmMessage" },
      [_c("p", [_vm._v("Something went wrong")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }